/*
 * @Author: wangliang
 * @Date: 2023-08-16 14:25:19
 * @LastEditTime: 2025-03-10 15:44:23
 * @LastEditors: JiGuangJie
 * @Description: 页面路由配置
 */

import { getRouterConfig } from '@/config/router-config';
import { Context } from '@/redux';
import React, { useContext, useMemo } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const AppRouter = () => {
	const { state } = useContext(Context);
	const router = useMemo(() => {
		return createBrowserRouter(getRouterConfig(state.isMobile));
	}, [state.isMobile]);

	return (
		<div className="page-content">
			<RouterProvider router={router}/>
		</div>

	);
};

export default AppRouter;