import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

if (!Object.hasOwn) {
	Object.defineProperty(Object, 'hasOwn', {
		value: function (object, property) {
			if (object == null) {
				throw new TypeError('Cannot convert undefined or null to object');
			}
			return Object.prototype.hasOwnProperty.call(Object(object), property);
		},
		configurable: true,
		enumerable: false,
		writable: true,
	});
}

if (!Array.prototype.at) {
	Array.prototype.at = function (index) {
		const adjustedIndex = index >= 0 ? index : this.length + index;
		return adjustedIndex < 0 || adjustedIndex >= this.length ? undefined : this[adjustedIndex];
	};
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<App/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
