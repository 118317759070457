/*
 * @Author: wangliang
 * @Date: 2023-05-31 14:07:22
 * @LastEditTime: 2024-12-25 17:08:50
 * @LastEditors: wangliang
 * @Description: 拦截器
 */
import axios from 'axios';
import { message } from 'antd';
import { getGin } from '@/util/util';
import { getExpiresTime } from '@/util/util';
import wx from 'weixin-js-sdk';
import { projectPath } from './index';

const service = axios.create();

// 是否正在刷新的标记
let isRefreshing = false;
// 重试队列，每一项将是一个待执行的函数形式
let requests = [];

const toLogin = () => {
	if (window.android) {
		window.android.logout && window.android.logout();
	}
	if (localStorage.getItem('isWeChat')) {
		wx.miniProgram.reLaunch ({
			url: '/pages/home/index',
		});
	} else {
		if (window.location.pathname.includes('manager')) {
			window.location.href = '/manager/login';
		} else {
			window.location.href = '/login';
		}
	}
	localStorage.removeItem('gin-token');
	localStorage.removeItem('gin-user-detail');
	sessionStorage.clear();
};
// 请求拦截器
service.interceptors.request.use(async config => {
	const gin = getGin();
	config.headers = {
		'Content-Type': 'application/json;charset=utf-8',
		'X-Device-Type': window.innerWidth <= 768 ? 'mobile' : 'desktop'
	};
	if (gin.accessToken) {
		config.headers['Authorization'] = `Bearer ${gin.accessToken}`;

		return config;
	} else {
		return config;
	}
}, error => error);

// 响应拦截器
service.interceptors.response.use(async response => {
	// 根据返回不同的状态码做不同的事情
	if (response.status) {
		switch (response.status) {
			case 200:
				const code = response.data.code;
				if (code === 0) {
					return response.data;
				} else if (code === 1001) {
					toLogin();
					return response.data;
				} else if (code === 1002) {
					if (!isRefreshing) {
						isRefreshing = true;
						const gin = getGin();
						return axios.post(`${projectPath}/system/auth/refresh-token?refreshToken=${gin.refreshToken}`).then(res => {
							if (res.data.code === 0) {
								let data = res.data.data;
								data = {
									...data,
									expiresTime: getExpiresTime(data.expiresTime)
								};
								localStorage.setItem('gin-token', JSON.stringify(data));

								requests.forEach(cb => cb());
								requests = [];
								return service(response.config);
							}
						}).catch(res => {
							toLogin();
						}).finally(() => {
							isRefreshing = false;
						});
					} else {
						// 正在刷新token，返回一个未执行resolve的promise
						return new Promise((resolve) => {
							// 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
							requests.push(() => {
								resolve(service(response.config));
							});
						});
					}
				} else {
					return response.data;
				}
			case 401:
				break;
			case 403:
				break;
			default:
				message.error(response.data.msg).then(r => {
				});
		}
	} else {
		return response;
	}
}, error => {
	console.log(error);
});

/**
 * 封装get方法
 * @param url 请求地址
 * @param params get参数，默认可以不传
 * @return {Promise<unknown>}
 */
const get = (url, params = {}) => {
	return new Promise((resolve, reject) => {
		service.get(url, { params: { ...params } }).then(response => {
			resolve(response);
		}).catch(error => {
			reject(error);
		});
	});
};

/**
 * 封装post请求
 * @param url
 * @param data
 * @param params
 * @return {Promise<unknown>}
 */
const post = (url, data, params = {}) => {
	return new Promise((resolve, reject) => {
		if (data === null) {
			service.post(url, data, { params }).then(response => {
				resolve(response);
			}).catch(error => {
				reject(error);
			});
		} else {
			service.post(url, data).then(response => {
				resolve(response);
			}).catch(error => {
				reject(error);
			});
		}
	});
};

/**
 * 封装put方法
 * @param url 请求地址
 * @param params put参数，默认可以不传
 * @return {Promise<unknown>}
 */
const put = (url, params = {}) => {
	return new Promise((resolve, reject) => {
		service.put(url, params).then(response => {
			resolve(response);
		}).catch(error => {
			reject(error);
		});
	});
};

/**
 * 封装delete方法
 * @param url 请求地址
 * @param params delete参数，默认可以不传
 * @return {Promise<unknown>}
 */
const del = (url, params = {}) => {
	return new Promise((resolve, reject) => {
		service.delete(url, { params: { ...params } }).then(response => {
			resolve(response);
		}).catch(error => {
			reject(error);
		});
	});
};

export { get, post, put, del };