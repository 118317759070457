/**
 * @Author : JiGuangJie
 * @Date : 2025/3/10
 * @Time : 14:42
 * @Path : src/config
 * @Version : 1.0.0
 * @Description : 组件描述 路由配置
 */
import { Spin } from 'antd';
import { Skeleton } from 'antd-mobile';
import React, { lazy, Suspense } from 'react';
import { Navigate } from 'react-router';
import { redirect } from 'react-router-dom';

const Login = lazy(() => import('@/pages/login'));
const Home = lazy(() => import('@/pages/home'));
const Chat = lazy(() => import('@/pages/home/chat'));
const UserCenterPage = lazy(() => import('@/pages/home/user-center'));
const QuestionAnswer = lazy(() => import('@/pages/home/chat/question-answer'));
const MobileChatPages = lazy(() => import('@/pages/home/mobile-chat'));
const CheckList = lazy(() => import('@/pages/home/mobile-chat/check-list'));
const EnterpriseCustom = lazy(() => import('@/pages/home/mobile-chat/enterprise-custom'));
const EmergencyScience = lazy(() => import('@/pages/home/mobile-chat/emergency-science'));
const GeneralScenePages = lazy(() => import('@/pages/home/mobile-chat/general-scene'));
const ImageAnalysis = lazy(() => import('@/pages/home/mobile-chat/image-analysis'));
const MyKnowLedgeBase = lazy(() => import('@/pages/home/mobile-chat/my-knowledge-base'));
const PolicyAnalysis = lazy(() => import('@/pages/home/mobile-chat/policy-analysis'));
const SearchLedger = lazy(() => import('@/pages/home/mobile-chat/search-ledger'));
const SetExams = lazy(() => import('@/pages/home/mobile-chat/set-exams'));
const RiskAnalysis = lazy(() => import('@/pages/home/mobile-chat/risk-analysis'));
const VideoLearning = lazy(() => import('@/pages/home/mobile-chat/video-learning'));

const Page404 = lazy(() => import('@/pages/page404'));
const PrivacyTerms = lazy(() => import('@/pages/login/protocol/privacy-terms'));
const UserAgreement = lazy(() => import('@/pages/login/protocol/user-agreement'));
const FilePreview = lazy(() => import('@/pages/file-preview'));
const Transit = lazy(() => import('@/pages/login/transit'));
const ThirdPartLogin = lazy(() => import('@/pages/third-part-login'));


const loadingElement = (isMobile) => {
	return (<div className="page-loading">
		{
			isMobile ? <>
				<Skeleton.Title animated/>
				<Skeleton.Paragraph lineCount={15} animated/>
			</> : <Spin/>
		}
	</div>);
};

const getRouterConfig = (isMobile) => {
	return [
		{
			path: '/',
			element: (<Suspense fallback={loadingElement(isMobile)}><Navigate to={'home'}/></Suspense>),
			meta: {
				title: '首页',
			},
		},
		{
			path: 'home',
			element: (<Suspense><Home/></Suspense>),
			children: [
				{
					path: 'chat',
					element: (<Suspense fallback={loadingElement(isMobile)}><Chat/></Suspense>),
					children: [
						{
							path: 'question-answer',
							element: (<Suspense fallback={loadingElement(isMobile)}><QuestionAnswer/></Suspense>)
						}
					],
					meta: {
						title: '问答页',
					},
				},
				{
					path: 'm',
					element: (<Suspense fallback={loadingElement(isMobile)}><MobileChatPages/></Suspense>),
					children: [
						{
							path: 'check-list/:id',
							element: (<Suspense fallback={loadingElement(isMobile)}><CheckList/></Suspense>)
						},
						{
							path: 'enterprise-custom/:id',
							element: (<Suspense fallback={loadingElement(isMobile)}><EnterpriseCustom/></Suspense>)
						},
						{
							// path: 'general-scene',
							path: 'general-scene/:id',
							element: (<Suspense fallback={loadingElement(isMobile)}><GeneralScenePages/></Suspense>)
						},
						{
							path: 'image-analysis/:id',
							element: (<Suspense fallback={loadingElement(isMobile)}><ImageAnalysis/></Suspense>)
						},
						{
							path: 'my-knowledge-base/:id',
							element: (<Suspense fallback={loadingElement(isMobile)}><MyKnowLedgeBase/></Suspense>)
						},
						{
							path: 'policy-analysis/:id',
							element: (<Suspense fallback={loadingElement(isMobile)}><PolicyAnalysis/></Suspense>)
						},
						{
							path: 'search-ledger/:id',
							element: (<Suspense fallback={loadingElement(isMobile)}><SearchLedger/></Suspense>)
						},
						{
							path: 'set-exams/:id',
							element: (<Suspense fallback={loadingElement(isMobile)}><SetExams/></Suspense>)
						},
						{
							path: 'risk-analysis/:id',
							element: (<Suspense fallback={loadingElement(isMobile)}><RiskAnalysis/></Suspense>)
						},
						{
							path: 'video-learning/:id',
							element: (<Suspense fallback={loadingElement(isMobile)}><VideoLearning/></Suspense>)
						},
						{
							path: 'emergency-science/:id',
							element: (<Suspense fallback={loadingElement(isMobile)}><EmergencyScience/></Suspense>)
						}
					]
				},
				{
					path: 'user-center',
					element: (<Suspense fallback={loadingElement(isMobile)}><UserCenterPage/></Suspense>)
				}
			],
			meta: {
				title: '首页',
			},
		},
		{
			path: '/login',
			element: <Suspense fallback={loadingElement(isMobile)}>
				<Login/>
			</Suspense>,
			meta: {
				title: '登录',
				noLogin: true,
				hideMenu: true
			}
		},
		{
			path: '/user-agreement',
			element: <Suspense fallback={loadingElement(isMobile)}>
				<UserAgreement/>
			</Suspense>,
			meta: {
				title: '用户协议',
			}
		},
		{
			path: '/privacy-terms',
			element: <Suspense fallback={loadingElement(isMobile)}>
				<PrivacyTerms/>
			</Suspense>,
			meta: {
				title: '用户协议',
			}
		},
		{
			path: '/transit',
			element: <Suspense fallback={loadingElement(isMobile)}>
				<Transit/>
			</Suspense>,
			meta: {
				title: '微信扫码登录成功',
			}
		},
		{
			path: '/file-preview',
			element: <Suspense fallback={loadingElement(isMobile)}>
				<FilePreview isMobile={isMobile}/>
			</Suspense>,
			meta: {
				title: '文件预览',
			}
		},
		{
			path: '/third-part-login',
			element: <Suspense fallback={loadingElement(isMobile)}>
				<ThirdPartLogin/>
			</Suspense>,
			meta: {
				title: '第三方登录',
			}
		},
		{
			path: '*',
			element: <Suspense><Page404/></Suspense>,
			meta: {
				title: '404',
				noLogin: true,
				hideMenu: true
			}
		},
	];
};

export { getRouterConfig };