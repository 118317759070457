/*
 * @Author: wangliang
 * @Date: 2023-04-21 09:35:27
 * @LastEditTime: 2025-03-28 11:22:33
 * @LastEditors: wangliang
 * @Description: 公共方法
 */
import MarkdownIt from "markdown-it";
import  * as XLSX from "xlsx"
import { projectPath, apis } from "@/api"
import { message } from "antd";
import floderSvg from "@/assets/images/chat/icon_folder.svg"
import fileSvg  from "@/assets/images/chat/icon_file.svg"
export const getGin = () => {
    try {
        return JSON.parse(localStorage.getItem('gin-token') || '{}');
    } catch {
        return {};
    }
};

/**
 * @description 返回选择器Options
 * @param {Array} arr 原始数组
 * @param {String} labelKey labelKey
 * @param {String} valueKey valueKey
 */
export const getSelectOptions = (arr, labelKey, valueKey) => {
    let list = [...arr]
    list.forEach(item => {
        item.label = item[labelKey]
        item.value = item[valueKey]
    })
    return (list)
}
/**
 * @description 数组根据key匹配中文名称
 * @param {Array} arr 原始数组
 * @param {String} keyName keyName
 * @param {String} key key
 */
export const matchText = (arr, keyName, outputKey, key) => {
    let findIndex = -1
    findIndex = arr.findIndex(item => {return item[keyName] === key});
    return findIndex > -1 ? arr[findIndex][outputKey] : ''
}

/**
 * @description expiresTime格式转换
 * @param {Array} arr 原始数组
 */
export const getExpiresTime = (arr) => {
    const doValue = (val) => {
        if(val*1 < 10) {
            return '0' + val
        }else if(!val){
            return '00'
        }else {
            return val
        }
    }
    let str = ''
    if(arr.length > 6) {
        str = `${arr[0]}-${doValue(arr[1])}-${doValue(arr[2])} ${doValue(arr[3])}:${doValue(arr[4])}:${doValue(arr[5])}:${arr[6]}`
    }else {
        str = `${arr[0]}-${doValue(arr[1])}-${doValue(arr[2])} ${doValue(arr[3])}:${doValue(arr[4])}:${doValue(arr[5])}`
    }
    return str
}
/**
 * @description 生成对话id
 */
export const getReqId = () => {
    return (`${new Date().getTime()}-${Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000}`)
}
/**
 * @description markdown文本处理
 */
export const getHtml = (answer, parent) => {
    function wrapVideoLinksWithTags(text) {
        // 定义一个正则表达式来匹配常见的视频文件链接
        const videoRegex = /https?:\/\/[^\s]+(?:\.mp4|\.mov|\.ogg|\.webm)[^\s]*/gi;
        // 使用replace方法和回调函数来处理每个匹配到的链接
        return text.replace(videoRegex, function(url) {
            // 创建video元素的HTML字符串
            return `<video controls><source src="${url}" type="video/mp4"></video>`;
            // 注意：这里假设所有视频都是mp4格式，你可能需要根据实际的视频类型调整type属性
        });
    }
    let _answer = answer
    // let _answer = wrapVideoLinksWithTags(answer)
    if (answer.includes('<|startofexec|>')) {
        let url = answer.replace('<|startofexec|>', '').replace('<|endofexec|>', '').replace('vfiles.gingpt.cn', 'www.gingpt.cn')
        let type = url.slice(-3)
        if (type === 'wav') {
            return `<audio controls src=${url}><meta name="referrer" content="never"></audio>`
        } else {
            let poster = url.slice(0, -4) + '.png'
            return `<video controls >
                <source src=${url} poster=${poster}>
            </video>`
        }

    } else {
        _answer = answer
    }
    return _answer.replace(/\\n/g, '\n').replace(/\r\n/g, '\n').replace(/\n\n(\d+)/g, '\n$1').replaceAll('<think>', '<blockquote>').replaceAll('</think>', '</blockquote>').replaceAll('<exam>', '<blockquote>').replaceAll('</exam>', '</blockquote>')
}
/**
 * @description markdown文本处理
 * @param {Number} arr 文件字节大小
 */
export const getFileSize = (sizeInBytes) => {
    if (sizeInBytes <= 0) return "0 B";

    var units = ["B", "K", "M", "G", "T"];
    var i = Math.floor(Math.log(sizeInBytes) / Math.log(1024));

    return parseFloat((sizeInBytes / Math.pow(1024, i)).toFixed(2)) + " " + units[i];
}

/**
 * @description 下载文件
 * @param {Object} file 文件信息
 */
export const downLoadFile = (file, cb) => {
    if(window.innerWidth <= 768) {
        window.location.href = file.fileUrl
    }else {
        const path = file.fileUrl
        const url = `${projectPath}/upload/downLoadByUrl?url=${path}`
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = "blob";
        xhr.onload = function () {
            console.log(this);
            if (this.status === 200) {
                var blob = this.response;
                console.log(this);
                if (navigator.msSaveBlob == null) {
                    var a = document.createElement('a');
                    a.download = file.fileName;
                    a.href = URL.createObjectURL(blob);
                    document.body.appendChild(a); 
                    a.click();
                    URL.revokeObjectURL(a.href);
                    document.body.removeChild(a)
                } else {
                    navigator.msSaveBlob(blob, file.fileName);
                }
            }else {
                // 报错信息
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsArrayBuffer(this.response);
                }).then(buffer => {
                    const decoder = new TextDecoder('utf-8');
                    const text = decoder.decode(buffer);
                    const _message = JSON.parse(text) ? JSON.parse(text).message : text
                    message.error(_message)
                });
            }
        };
        xhr.send()
    }
    
}
/**
 * @description 匹配markdown中的table
 * @param {String} mdContent markdown文本
 */
export const matchTable = (mdContent) => {
    const mdParser = MarkdownIt();
    // 将Markdown内容转换为HTML
    const html = mdParser.render(mdContent);
    // 使用HTML表格正则表达式匹配表格部分
    const tableRegex = /<table[\s\S]*?<\/table>/g;
    return html.match(tableRegex);
};

/**
 * @description 下载markdown中的table
 * @param {String} mdContent markdown文本
 * @param {String} fileNmae 导出文件名
 */
export const markdownTableToExcel = (mdContent, fileNmae) => {
    // 解析Markdown内容
    const matchedTables = matchTable(mdContent);
    if (matchedTables) {
      for (const table of matchedTables) {
        // 将HTML表格转换为DOM结构（这里假设在浏览器环境下）
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = table;

        const tableElement = tempDiv.querySelector("table");
        if (!tableElement) continue;

        // 将DOM表格转换为二维数组
        const tableData = Array.from(tableElement.rows).map((row) =>
          Array.from(row.cells).map((cell) => cell.innerText.trim().replaceAll('<br>', '\n'))
        );

        // 创建工作簿对象
        const workbook = XLSX.utils.book_new();

        // 将表格数据转换为 worksheet 对象
        const worksheet = XLSX.utils.aoa_to_sheet(tableData);

        // 添加worksheet到workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        // 导出 Excel 文件
        const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

        // 创建 Blob 并下载
        const blob = new Blob([wbout], { type: "application/octet-stream" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `${fileNmae || 'table'}.xlsx`;
        document.body.appendChild(link);

        // 触发点击以下载
        link.click();

        // 清理
        setTimeout(() => {
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        }, 0);
      }
    }
}
/**
 * @description 判断能否播放视频
 * @param {String} markdown markdown文本
 * @param {String} fileNmae 导出文件名
 */
export const isForbidPlay = (answer) => {
    let flag = true
    if (answer.includes('<|startofexec|>')) {
        let url = answer.replace('<|startofexec|>', '').replace('<|endofexec|>', '').replace('vfiles.gingpt.cn', 'www.gingpt.cn')
        if(url === 'xxx.xxxx.xxxxx') {
            flag = false
        }
    }

    return flag
}
/**
 * @description 复制markdown
 * @param {String} markdown markdown文本
 */
export const oncopy = (markdown) => {
    var input = document.createElement("textarea");
    input.value = markdown.replace('<|startofexec|>', '').replace('<|endofexec|>', '').replace(/<think>[\s\S]*?<\/think>/g, '');
    // input.value = item.chatContents[0].content.replace('<|startofexec|>', '').replace('<|endofexec|>', '');
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
    message.success('复制Markdown成功')
}
/**
 * @description 复制内容
 * @param {String} markdown markdown文本
 */
export const onCopyContent = (markdown) => {
    var input = document.createElement("textarea");
    let _markdown = markdown.replace('<|startofexec|>', '').replace('<|endofexec|>', '').replace(/<think>[\s\S]*?<\/think>/g, '');
    // 去除标题
    _markdown = markdown.replace(/^#{1,6}\s+/gm, '');

    // 去除加粗和斜体
    _markdown = _markdown.replace(/\*\*(.*?)\*\*/g, '$1');
    _markdown = _markdown.replace(/\*(.*?)\*/g, '$1');
    _markdown = _markdown.replace(/__([\s\S]+?)__/g, '$1');
    _markdown = _markdown.replace(/_([\s\S]+?)_/g, '$1');

    // 去除引用
    _markdown = _markdown.replace(/^>\s?/gm, '');

    // 移除无序列表
    _markdown = _markdown.replace(/^\s*[-*+]\s+/gm, '');
    // 移除有序列表
    _markdown = _markdown.replace(/^\s*\d+\.\s+/gm, '');

    // 去除列表
    _markdown = _markdown.replace(/^\s*[-*+] \s+/gm, '');

    // 去除代码块
    _markdown = _markdown.replace(/`{3}[\s\S]*?`{3}/g, '');
    _markdown = _markdown.replace(/`([\s\S]+?)`/g, '$1');

    // 去除链接
    _markdown = _markdown.replace(/\[([^\]]+)\]\([^\)]+\)/g, '$1');

    // 去除换行符
    _markdown = _markdown.replace(/\n+/g, '\n');

    // 去除多余的空格
    _markdown = _markdown.replace(/\s{2,}/g, ' ');
    input.value = _markdown.trim()
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
    message.success('复制成功')
}
/**
 * @description 获取树结构数据
 * @param {Function} treeData 树结构
 */
export const  transformTreeData = (treeData, parentKey=null) => {
    return treeData.map((item, index) => {
        let newNode =  {
            ...item,
            title: item.fileName || item.name || item.title, // 如果存在 name 则使用 name，否则保留原来的 title
            key: item.key || `${parentKey}-${index}`, // 如果没有 key，则生成一个新的 key
            selectable: false,
            // isLeaf: (item.children || item.details) ? false : true,
            icon: <img src={(item.detailId || !item.id) ? fileSvg : floderSvg}/>
        };
        if (Array.isArray(item.children) && item.children.length) {
            newNode.children = transformTreeData(item.children, newNode.key);
        }
        if (Array.isArray(item.details) && item.details.length) {
            newNode.children = transformTreeData(item.details, newNode.key);
        }
        return newNode
    })
}
/**
 * @description 秒转化为时间格式
 * @param {Function} timeIndex 
 */
export const  timeIndexToStr = (timeIndex) => {
    const hours = Math.floor(timeIndex / 3600); // 计算小时
    const minutes = Math.floor((timeIndex - (hours * 3600)) / 60); // 计算分钟
    const seconds = timeIndex - (hours * 3600) - (minutes * 60); // 计算剩余的秒数

    // 使用 padStart 方法确保每部分至少有两位数字，不足两位则前面补0
    const hoursStr = String(hours).padStart(2, '0');
    const minutesStr = String(minutes).padStart(2, '0');
    const secondsStr = String(seconds).padStart(2, '0');

    return `${hoursStr}:${minutesStr}:${secondsStr}`;
}