/*
 * @Author: wangliang
 * @Date: 2023-06-05 09:07:12
 * @LastEditTime: 2025-03-05 15:48:19
 * @LastEditors: wangliang
 * @Description: api请求
 */
import { get, post, put, del } from './request';
// const basePath = ''
const basePath = '/ginkgo';
const projectPath = basePath + '/system-api';
const chatPath = basePath + '/chat-api';
const payPath = basePath + '/pay-api';

// 验证码登录
const userLogin = params => post(`${projectPath}/system/auth/sms-login`, params);
// 用户注册
const register = params => post(`${projectPath}/system/auth/register`, params);
// 获取验证码
const sendSmsCode = params => post(`${projectPath}/system/auth/send-sms-code`, params);
// 登出
const logout = () => post(`${projectPath}/system/auth/logout`, {});
// 获取邀请码列表
const getInvitationCodeList = params => get(`${projectPath}/system/invitation-code/page`, params);
// 标记已发送的邀请码
const markSentBatch = params => put(`${projectPath}/system/invitation-code/markSentBatch`, params);
// 生成请码
const createInvitationCode = params => post(`${projectPath}/system/invitation-code/create`, params);
// 用户列表
const getUserList = params => get(`${projectPath}/system/user/page`, params);
// 管理用户列表
const getManagerUserList = params => get(`${projectPath}/system/user/manager/page`, params);
// 修改账号状态
const putAccountStatus = params => put(`${projectPath}/system/user/update-status`, params);
// 获取角色列表
const getRoleList = params => get(`${projectPath}/system/role/page`, params);
// 获取角色列表
const putRoleStatus = params => put(`${projectPath}/system/role/update-status`, params);
// 创建角色
const createRole = params => post(`${projectPath}/system/role/create`, params);
// 删除角色
const delRole = id => del(`${projectPath}/system/role/delete?id=${id}`);
// 修改角色
const editRole = params => put(`${projectPath}/system/role/update`, params);
// 获取用户信息
const getUserInfo = params => get(`${projectPath}/system/user/get`, params);
// 用户分配角色
const assignUserRole = params => post(`${projectPath}/system/permission/assign-user-role`, params);
// 获取全部角色
const getRoleListAll = params => get(`${projectPath}/system/role/list-all-simple`, params);
// 申请使用列表
const registerApprovalPage = params => get(`${projectPath}/system/user-register-approval/page`, params);
// 申请审核
const registerApproval = params => put(`${projectPath}/system/user-register-approval/approval`, params);
// 获取用户权限信息
const getPermissionInfo = params => get(`${projectPath}/system/auth/get-permission-info`, params);
// 获取会话列表
const getSessionListByUser = params => get(`${chatPath}/chat/getSessionListByUser`, params);
// 创建会话
const createSession = params => post(`${chatPath}/chat/createSession`, params);
// 修改会话名称
const updateSessionName = params => put(`${chatPath}/chat/updateSessionName`, params);
// 删除会话
const deleteSession = id => del(`${chatPath}/chat/deleteSession?id=${id}`);
// 清空会话
const deleteAllSession = () => del(`${chatPath}/chat/deleteAllSession`);
// 获取问答列表
const getQaList = params => get(`${chatPath}/v2/chat/getDialogue`, params);
// 停止生成回答
const stopQuestion = params => put(`${chatPath}/chat/stopQuestion`, params);
// 生成认证
const getCaptcha = params => post(`${projectPath}/captcha/get`, params);
// 验证认证
const checkCaptcha = params => post(`${projectPath}/captcha/check`, params);
// 密码登录
const passwordLogin = params => post(`${projectPath}/system/auth/login`, params);
// 创建管理账号
const createAdminUser = params => post(`${projectPath}/system/user/create`, params);
// 编辑管理账号
const updateAdminUser = params => put(`${projectPath}/system/user/update`, params);
// 删除管理账号
const delAdminUser = id => del(`${projectPath}/system/user/delete?id=${id}`);
// 管理账号修改密码
const updateAdminPassword = params => put(`${projectPath}/system/user/update-password`, params);
// check资源
const checkResources = params => get(`${chatPath}/chat/checkResources`, params);
// 更改回答状态（点赞点踩）
const updateMark = params => put(`${chatPath}/chat/updateMark`, params);
// 修改邀请码备注
const updateRemark = params => put(`${projectPath}/system/invitation-code/update-remark`, params);
// 获取问答记录
const getChatPage = params => get(`${chatPath}/chat/page`, params);
// 获取用户反馈
const getfeedbackPage = params => get(`${chatPath}/chat/feedbackPage`, params);
// 获取推荐问题
const getRecommend = params => get(`${projectPath}/system/scene/recommend`, params);
// 获取包月包年价格
const getGoodsList = params => get(`${payPath}/goods/list-user`, params);
// 创建订单
const createOrderNo = params => post(`${payPath}/pay/order/createOrderNo`, params);
// 提交订单
const submitOrder = params => post(`${payPath}/pay/order/submit`, params);
// 获取订单状态订单
const getOrderStatus = params => get(`${payPath}/pay/order/get`, params);
// 获取用户详细信息
const getUserDetail = params => get(`${projectPath}/system/user/getUserDetail`, params);
// 修改用户信息
const updateProfile = params => put(`${projectPath}/system/user/updateProfile`, params);
// 获取订单列表
const getOrderPage = params => get(`${payPath}/pay/order/pageByUserId`, params);
// 上传文件
const uploadFile = params => post(`${projectPath}/upload/file`, params);
// 批量上传文件
const uploadFiles = params => post(`${projectPath}/upload/files`, params);
// 用户反馈
const createFeedback = params => post(`${projectPath}/system/feedback/create`, params);
// 场景列表
const getSceneList = params => get(`${projectPath}/system/scene/type/detailList`, params);
// 左侧工具栏
const getSceneByUser = params => get(`${projectPath}/system/scene/getSceneByUser`, params);
// 微信扫码参数
const getWxWebInfo = params => get(`${projectPath}/system/auth/getWxWebInfo`, params);
// 微信扫码登录
const wxWebLogin = params => post(`${projectPath}/system/auth/wx-web-login`, params);
// 微信扫码注册
const wxWebRegister = params => post(`${projectPath}/system/auth/wx-web-register`, params);
// 场景关注
const sceneFollow = params => put(`${projectPath}/system/scene/follow`, params);
// 账号注销
const logOff = params => del(`${projectPath}/system/user/logOff`, params);
// 微信解绑
const wxUnbind = params => post(`${projectPath}/system/auth/wx-web-unbind`, params);
// 微信扫码绑定
const wxQrBind = params => post(`${projectPath}/system/auth/wx-web-qr`, params);
// 校验旧手机号
const checkSmsCode = params => post(`${projectPath}/system/auth/check-sms-code`, params);
// 校验新旧手机号
const checkPhoneNum = params => post(`${projectPath}/system/user/checkPhoneNum`, params);
// 更换新手机号
const updatePhoneNum = params => put(`${projectPath}/system/user/updatePhoneNum`, params);
// 获取非会员剩余使用次数
const getLimit = params => get(`${chatPath}/v2/chat/getLimit`, params);
// 获取推荐的问题
const getRelvantAndQestions = params => post(`${chatPath}/chat/getRelvantAndQestions`, params);
// 获取未登录推荐问题
const getChatRecommend = params => get(`${projectPath}/system/scene/recommendByNologin`, params);
// 获取未登录推荐问题回答
const getAnswerByNologin = params => get(`${projectPath}/system/scene/getAnswerByNologin`, params);
// 获取知识库列表
const getMyLib = params => get(`${projectPath}/system/scene/knowledge/lib/getMyLib`, params);
// 新建知识库
const createLib = params => post(`${projectPath}/system/scene/knowledge/lib/create`, params);
// 编辑知识库
const updateLib = params => put(`${projectPath}/system/scene/knowledge/lib/update`, params);
// 删除知识库
const delLib = ids => del(`${projectPath}/system/scene/knowledge/lib/delete?ids=${ids}`);
// 获取知识库文件列表
const getLibFile = params => get(`${projectPath}/system/scene/knowledge/lib/getLibFile`, params);
// 删除知识库文件
const delKnowledge = ids => del(`${projectPath}/system/scene/knowledge/delete?ids=${ids}`);
// 知识库参考资料
const getSourceDocuments = params => get(`${chatPath}/v2/chat/getSourceDocuments`, params);
// 文件id获取文件
const getFileInfo = params => get(`${projectPath}/upload/getFileInfo`, params);
// 获取插件数据
const getPluginsInfo = params => get(`${chatPath}/v2/chat/plugins/getInfoV2`, params);
// const getPluginsInfo = params => get(`${chatPath}/v2/chat/plugins/getInfo`, params);
// 通过url下载文件
const downLoadByUrl = params => get(`${projectPath}/upload/downLoadByUrl`, params);
// 第三方登录
const thirdPartLogin = params => post(`${projectPath}/thrid-part/login`, params);
// 联网搜索信息
const getWebSearch = params => get(`${chatPath}/v2/chat/getWebSearch`, params);
// 普通用户每日免费次数
const getUnmemberLimit = params => get(`${chatPath}/v2/chat/getUnmemberLimit`, params);
// 获取活动
const getDisplayCardByLogin = () => get(`${projectPath}/system/displayCard/getDisplayCardByLogin`);

const apis = {
	getApi: {
		getInvitationCodeList,
		getUserList,
		getManagerUserList,
		getRoleList,
		getUserInfo,
		getRoleListAll,
		registerApprovalPage,
		getPermissionInfo,
		getSessionListByUser,
		getQaList,
		checkResources,
		getChatPage,
		getfeedbackPage,
		getRecommend,
		getGoodsList,
		getOrderStatus,
		getUserDetail,
		getOrderPage,
		getSceneList,
		getSceneByUser,
		getWxWebInfo,
		getLimit,
		getChatRecommend,
		getAnswerByNologin,
		getMyLib,
		getLibFile,
		getSourceDocuments,
		getFileInfo,
		getPluginsInfo,
		downLoadByUrl,
		getWebSearch,
		getUnmemberLimit,
		getDisplayCardByLogin
	},
	postApi: {
		userLogin,
		register,
		sendSmsCode,
		logout,
		createInvitationCode,
		createRole,
		assignUserRole,
		createSession,
		getCaptcha,
		checkCaptcha,
		passwordLogin,
		createAdminUser,
		createOrderNo,
		submitOrder,
		uploadFile,
		uploadFiles,
		createFeedback,
		wxWebLogin,
		wxWebRegister,
		wxUnbind,
		wxQrBind,
		checkSmsCode,
		checkPhoneNum,
		getRelvantAndQestions,
		createLib,
		thirdPartLogin

	},
	putApi: {
		markSentBatch,
		putAccountStatus,
		putRoleStatus,
		editRole,
		registerApproval,
		updateSessionName,
		stopQuestion,
		updateAdminUser,
		updateAdminPassword,
		updateMark,
		updateRemark,
		updateProfile,
		sceneFollow,
		updatePhoneNum,
		updateLib
	},
	delApi: {
		delRole,
		deleteSession,
		deleteAllSession,
		delAdminUser,
		logOff,
		delLib,
		delKnowledge
	}
};


export {
	apis,
	projectPath,
	chatPath
};
