/**
 * @Author: wangliang
 * @Date: 2025-02-19 16:56:02
 * @LastEditors: JiGuangJie
 * @LastEditTime: 2025-03-31 12:00:45
 * @FilePath: src/App.js
 * @Version: 1.0.0
 * @Description: 组件描述
 */

import { mouseClickEvent } from '@/util/event-bus';
import { getExpiresTime } from '@/util/util';
import { useEffect, useReducer, useRef, useState } from 'react';
import { systemInfo } from '@/config/config';
import { Context, reducer } from '@/redux';
import AppRouter from '@/router';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import './App.css';

dayjs.locale('zh-cn');

function App() {
	const [state, dispatch] = useReducer(reducer, {});
	const appRef = useRef(null);
	const urlSearchParams = new URLSearchParams(
		window.location.href.split('?')[1],
	);
	const urlParams = Object.fromEntries(urlSearchParams.entries());

	const updateMetaTags = () => {
		document.title = '余小应';
		// 更新 keywords
		const keywordsMetaTag = document.querySelector('meta[name="keywords"]');
		if (keywordsMetaTag) {
			keywordsMetaTag.setAttribute('content', 'keywords');
		}

		// 更新 description
		const descriptionMetaTag = document.querySelector('meta[name="description"]');
		if (descriptionMetaTag) {
			descriptionMetaTag.setAttribute('content', 'description');
		}

		// 更新 favicon
		const newFavicon = document.createElement('link');
		newFavicon.rel = 'icon';
		newFavicon.href = 'yu_favicon.ico' + '?v=' + Date.now(); // 替换为新图标的路径

		const oldFavicon = document.querySelector('link[rel~="icon"]');
		if (oldFavicon && oldFavicon.parentNode) {
			oldFavicon.parentNode.removeChild(oldFavicon);
		}
		document.head.appendChild(newFavicon);
	};

	useEffect(() => {
		// const urlParams = new URLSearchParams(window.location.search);
		// const token = urlParams.get('token');
		const hostname = window.location.hostname;
		if (hostname === 'xiaoying.gingpt.cn') {    // 余小应
			updateMetaTags();
			dispatch({ type: 'systemInfo', data: systemInfo[1] });
		} else {
			dispatch({ type: 'systemInfo', data: systemInfo[0] });
		}

		const handleResize = () => {
			dispatch({ type: 'isMobile', data: window.innerWidth <= 768 });
		};
		handleResize(); // 初始化时检测一次
		window.addEventListener('resize', handleResize);

		const touchMoveHandler = (e) => {
			const generalSceneInputDom = document.getElementById('general-scene-input');
			const myKnowledgeBaseInputDom = document.getElementById('my-knowledge-base-input');
			const enterpriseCustomInputDom = document.getElementById('enterprise-custom-input');
			if (generalSceneInputDom) {
				generalSceneInputDom.blur();
			}
			if (myKnowledgeBaseInputDom) {
				myKnowledgeBaseInputDom.blur();
			}
			if (enterpriseCustomInputDom) {
				enterpriseCustomInputDom.blur();
			}
			// mouseClickEvent.emit(MouseEvent.TOUCH_MOVE);
		};

		appRef.current.addEventListener('touchmove', touchMoveHandler);

		if (urlParams.hasOwnProperty('isWeChat')) {
			localStorage.setItem('isWeChat', 'true');
		}

		if (urlParams.hasOwnProperty('accessToken')) {
			const data = {
				...urlParams,
				userId: parseInt(urlParams.userId),
				params: JSON.parse(urlParams.params),
				expiresTime: getExpiresTime(JSON.parse(urlParams.expiresTime))
			};
			localStorage.setItem('gin-token', JSON.stringify(data));
			if (urlParams.hasOwnProperty('isWeChat')) {
				localStorage.setItem('openId', data.params.openId);
			}
		}

		return () => {
			// 清理操作（如果有）
			window.removeEventListener('resize', handleResize);
			appRef.current.addEventListener('touchmove', touchMoveHandler);
		};
	}, []);

	return (
		<div className="App"
		     ref={appRef}
		>
			<ConfigProvider locale={zhCN}>
				<Context.Provider value={{ state, dispatch }}>
					<AppRouter/>
				</Context.Provider>
			</ConfigProvider>
		</div>
	);
}

export default App;
