/*
 * @Author: wangliang
 * @Date: 2023-10-11 14:58:46
 * @LastEditTime: 2024-09-19 16:16:50
 * @LastEditors: wangliang
 * @Description: 
 */
import { createContext } from 'react';
import { systemInfo } from '@/config/config';

const initState = {
	getScene: false, // 获取场景列表是否加载完成
	isChat: false, // 正在对话
	getLimit: false, // 非会员获取剩余使用次数
	limit: false, // 会员获取剩余使用次数
	memberInfo: {}, // 用户信息
	payModal: false, // 支付弹窗是否开启
	isResourceInsufficient: false, // 是否资源不足
	systemInfo: {},
	isMobile: false,    // 是否手机端，默认false
	lastRouterPath: '',  // 上次的路由地址,
	sceneList: [],    // 场景列表
	casaderValue: ['0-0'],
	currentNode: null
};

const Context = createContext({
	state: initState, dispatch: () => {
	}
});

const reducer = (state = initState, action) => {
	switch (action.type) {
		case 'getScene':
			return { ...state, getScene: action.data };
		case 'isChat':
			return { ...state, isChat: action.data };
		case 'getLimit':
			return { ...state, getLimit: action.data };
		case 'limit':
			return { ...state, limit: action.data };
		case 'memberInfo':
			return { ...state, memberInfo: action.data };
		case 'payModal':
			return { ...state, payModal: action.data };
		case 'isResourceInsufficient':
			return { ...state, isResourceInsufficient: action.data };
		case 'systemInfo':
			return { ...state, systemInfo: action.data };
		case 'isMobile':
			return { ...state, isMobile: action.data };
		case 'lastRouterPath':
			return { ...state, lastRouterPath: action.data };
		case 'sceneList':
			return { ...state, sceneList: action.data };
		case 'casaderValue':
			return { ...state, casaderValue: action.data };
		case 'currentNode':
			return { ...state, currentNode: action.data };
		default:
			return state;
	}
};

export { Context, reducer };