/**
 * @Author: JiGuangJie
 * @Date: 2025-03-13 10:59:36
 * @LastEditors: JiGuangJie
 * @LastEditTime: 2025-03-13 11:05:11
 * @FilePath: src/util/event-bus/BaseEvent.js
 * @Version: 1.0.0
 * @Description: 组件描述
 */

import EventEmitter from 'eventemitter3'; // 导入EventEmitter库

/**
 * BaseEvent类用于创建事件总线，支持事件的注册、注销和触发
 */
class BaseEvent {
	/**
	 * 构造函数
	 * @param {Object} options - 可选配置对象
	 */
	constructor(options = {}) {
		this.options = options; // 初始化选项配置，默认为空对象
		this.eventBus = new EventEmitter(); // 创建一个新的事件发射器实例
	}

	/**
	 * 注册事件监听
	 * @param {string} eventName - 事件名称
	 * @param {Function} callback - 事件触发时的回调函数
	 */
	on(eventName, callback) {
		this.eventBus.on(eventName, callback); // 添加事件监听
	}

	/**
	 * 注销事件监听
	 * @param {string} eventName - 事件名称
	 * @param {Function} [callback] - 可选的回调函数，如果提供则注销特定回调
	 */
	off(eventName, callback) {
		if (callback) {
			this.eventBus.off(eventName, callback); // 注销指定的回调
		} else {
			this.eventBus.off(eventName); // 注销所有指定事件的回调
		}
	}

	/**
	 * 触发事件
	 * @param {string} eventName - 事件名称
	 * @param {...any} args - 事件触发时传递的参数
	 */
	emit(eventName, ...args) {
		this.eventBus.emit(eventName, ...args); // 触发指定事件并传递参数
	}
}

// 导出BaseEvent类
export default BaseEvent;