/*
 * @Description: 
 * @Author: wangyan
 * @Date: 2024-08-23 10:21:55
 * @LastEditors: wangliang
 * @LastEditTime: 2025-02-25 15:17:19
 */
/**
 * @Author : JiGuangJie
 * @Date : 2024/8/22
 * @Time : 15:49
 * @Path : src/utils/event-bus
 * @Version : 1.0.0
 * @Description : 组件描述
 */
import BaseEvent from './BaseEvent';

const createEventBus = () => new BaseEvent();
// 路由跳转
const mouseClickEvent = createEventBus();

export {
	mouseClickEvent,
};
